import React from 'react'; 
import { domain } from "../utils";
function Teaser( {author, shouldNotLazyload, websiteFontColor}) {

    return(
     <React.Fragment>
         {author && (
            <div className="teaser ">
              <div className="teaser__image-wrapper">
                <img className="teaser__image" src={author.picture.url ? author.picture.url : ""}  {...(!shouldNotLazyload && { loading: 'lazy' })} />
              </div>
              <div className="teaser__text">
                {author && (<h3 className="teaser__author-name">{author.name}</h3>)}
                {author && (<p className="teaser__author-job" style={{color: websiteFontColor }}>{author.jobTitle} </p>)}
              </div>
            </div>
         )}
     </React.Fragment>
    )
}

export default Teaser ; 
