import React from "react"

import Layout from "../components/wrappers/layout"
import SiteHeaderAlt from "../components/wrappers/site-header-alt"
import SEO from "../components/seo"
import Link from "../components/link";
import { domain } from "../utils"
import Header from '../components/header';
import TabbedItem from '../components/wrappers/tabbed-item';
import TabbedList from '../components/wrappers/tabbed-list';
import { graphql } from 'gatsby';
import Markdown from 'markdown-to-jsx';
import Cta from "../components/cta";
import Logos from "../components/logos";
import TabbedServices from "../components/tabbed-services";
import Faq from '../components/faq';
import { getSinglePage } from "../utils";
import classNames from "classnames";
import { useContext } from "react";
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider";
import { useEffect } from "react";
import { CustomHr } from "../components/CustomHr";

const CareerDetail = props => {
  const { career, footers, headers, theme, general } = props.data.strapi; 
  const { generalBackgroundColorDarkMode, generalBackgroundColorLightMode, clientLogosColorDarkMode, clientLogosColorLightMode , fontColorDarkMode, fontColorLightMode } = general; 
  const { copyright } = career;
  const language = props.pageContext.language
  const header = headers && headers.find((header)=>  header.language === language ); 
  const footer = footers && footers.find((footer)=>  footer.language === language ) || {}; 
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const { intro, faqs, pointslist, cta1, logos, cta2, pointstabs, collage, seo, darkTheme } = career;
  const background = intro.background && intro.background; 
  const backgroundColor = intro.backgroundColor && intro.backgroundColor; 
   //getting page first H1 element and setting this to meta  title and description if seo field in strapi not specified . ; 
  const imageBorderRadiusForServices = general?.imageBorderRadiusForServices;
  const pageFirstHeading = intro.header && (intro.header.supheading +" "+intro.header.heading );
  
  const seoTitle = seo && seo.metaData.metaTitle || pageFirstHeading ||"Careers detail";
  const seoDescription = seo && seo.metaData.metaDescription || pageFirstHeading|| "Careers detail ";
  const seoKeywords = seo && seo.metaData?.keywords;

  const webpages = career && career.webpages && career.webpages || {}; 
  const allPages = props.data?.allSitePage?.nodes; 
  const redirectTo = getSinglePage(webpages, allPages); 
  const isSubOpen = state.siteSubNav;
  const darkMode = state.darkModePreference === 'moon'; 
  const switchToDarkMode = theme.switchToDarkMode;
  const greySectionLightModeBgColor = general?.greySectionLightModeBgColor; 
  const greySectionDarkModeBgColor = general?.greySectionDarkModeBgColor; 
  const greySectionBgColor = darkMode ? greySectionDarkModeBgColor : greySectionLightModeBgColor; 
  const sectionBackgroundColor = darkMode ? generalBackgroundColorDarkMode : generalBackgroundColorLightMode; 
  const logosBgColor = darkMode ? clientLogosColorDarkMode : clientLogosColorLightMode; 
  const websiteFontColor = darkMode ? fontColorDarkMode : fontColorLightMode; 
  if (copyright) {
    footer.copyright = copyright;
  }
  useEffect(() => {
    const id = "careers"; 
    if (!isSubOpen[id]) {
      dispatch({ 
        type: "TOGGLE_SITE_SUB_NAV" , 
        payload: {
          id: id
        } 
      });
    } 
  }, []); 

  return (
    <Layout header={header} footer={footer} language={language} redirectTo={redirectTo}>
      <SEO
        websiteFontColor={websiteFontColor} 
        template="career-detail" 
        title={seoTitle} 
        description={seoDescription} 
        keywords={seoKeywords} 
        darkTheme={darkTheme}  
        darkMode={darkMode} 
        websiteBackgroundColor={sectionBackgroundColor}
      />
       {intro.header.heading && (
        <section className="hero hero--white service-detail-design" style={{backgroundColor: backgroundColor || sectionBackgroundColor}} >
          <div className="container-lg container align-items-end">
            <div className="hero__background hero__background--shadow">
              <div className="hero__background-inner">
                <img src={`${background && background.url}`} alt={intro.header.heading}  />
              </div>
            </div>
            <div className="hero__main">
              <div className="row align-items-end">
                <div className="col-12">
                  <div className="hero__content">
                    <header className="hero__header header">
                      <h1 className="header__heading">
                        {/* <small className="header__preheading">
                          {intro.header.heading}
                        </small> */}
                        {intro.header.heading}
                      </h1>
                    </header>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <SiteHeaderAlt header={header} redirectTo={redirectTo}>

        {intro && intro.body &&  (
          <section className="section--reduce" style={{backgroundColor: sectionBackgroundColor}}>
            <div className="container">
              <div className="row">
                <div className="offset-tw-1 col-tw-10 offset-lg-2 col-lg-7 rich-text">
                  <Markdown options={{
                    overrides: {a: {
                      component: Link
                    },                           
                    hr: { 
                        component: CustomHr,
                        props: {
                          borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                        }
                      }  
                    }
                  }}>
                    {intro.body}
                </Markdown>
                </div>
              </div>
            </div>
          </section>
        )}

        {pointslist && pointslist.length > 0 && (
          <section className={classNames({"section--reduce": !intro.body }, {"section": intro.body },  " rich-texts")} style={{backgroundColor: sectionBackgroundColor}}>
            <div className="container">
              <div className="row">
                {pointslist.map((pointlist, index) => {
                  const isOdd = index % 2;

                  return (
                    <div className={'col-tw-6 col-lg-5 rich-text '+(isOdd ? '' : 'offset-lg-1')}>
                      <h4
                        className={classNames({ "mt-0" : !(intro && intro.body)})}
                      >{pointlist.heading}&nbsp;</h4>
                      {pointlist.item && pointlist.item.length > 0 && (
                        <ul>
                          {pointlist.item.map((item, index) => {
                            return (
                              <li>                  
                                <Markdown options={{
                                  overrides: {a: {
                                    component: Link
                                  },                           
                                  hr: { 
                                      component: CustomHr,
                                      props: {
                                        borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                                      }
                                    }  
                                  }
                                }}>
                                  {item.richText}
                                </Markdown>
                              </li>
                           )
                          })}
                        </ul>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          </section>
        )}

        {cta1 && (
          <Cta 
            component={cta1} 
            greySectionBgColor={greySectionBgColor}
            fontColorLightMode={websiteFontColor}
          />
        )}
        
        {logos && (
          <Logos component={logos} sectionBackgroundColor={sectionBackgroundColor} logosBgColor={logosBgColor} />
        )}

        {pointstabs && (
          <TabbedServices 
            component={pointstabs} 
            language={language}  
            imageBorderRadiusForServices={imageBorderRadiusForServices}
            sectionBackgroundColor={sectionBackgroundColor}
            websiteFontColor={websiteFontColor}
        />
        )}

        {cta2 && (
          <Cta 
            component={cta2} 
            greySectionBgColor={greySectionBgColor}
            fontColorLightMode={websiteFontColor}
          />
        )}

        {collage && (
          <section className="section zero-margin_bottom" style={{backgroundColor: sectionBackgroundColor}}>
            <div className="collage collage--short">
              <div className="container">
                <div className="row">
                  <div className="offset-md-1 col-md-6 collage-item">
                    {collage[0] && (
                      <img 
                      src={collage[0].url} 
                      style={{borderRadius: imageBorderRadiusForServices}}
                      alt={collage[0].alternativeText}
                    />
                    )}
                  </div>
                  <div className="offset-md-1 col-md-3 collage-item">
                    {collage[1] && (
                      <img 
                      src={collage[1].url} 
                      style={{borderRadius: imageBorderRadiusForServices}}
                      alt={collage[1].alternativeText} 
                      loading="lazy"
                    />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {language === "en" && (
         <>
           {(faqs && faqs?.faqs.length > 0) && (
            <section className="section faqs section--grey" style={{backgroundColor: greySectionBgColor}}>
              {faqs.header && (
                <Header heading={faqs.header.heading} supheading={faqs.header.supheading} />
              )}

              {faqs.faqs && (
                <div className="container">
                  <div className="row">
                    {faqs.faqs.map((faq) => {
                      return (
                        <div className="col-tw-12 col-md-9 offset-md-1 col-lg-8 offset-lg-2">
                          <Faq heading={faq.heading} showWhitePlus={darkTheme || switchToDarkMode || darkMode} fontColorLightMode={fontColorLightMode}>
                            <Markdown options={{
                              overrides: {a: {
                                component: Link
                              },                           
                              hr: { 
                                  component: CustomHr,
                                  props: {
                                    borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                                  }
                                }  
                              }
                            }}>
                              {faq.richText || ''}
                            </Markdown>
                          </Faq>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </section>
          )}
         </>
        )}
      </SiteHeaderAlt>
    </Layout>
  )
}

export const pageQuery = graphql`
  query CareerDetail($id: ID!, $language: String!) {
    allSitePage {
      nodes {
        path
      }
    }
    strapi {
      general {
        primaryBtn
        blogsOverviewHeadline
        imageBorderRadiusForServices
        greySectionLightModeBgColor
        greySectionDarkModeBgColor
        generalBackgroundColorDarkMode
        generalBackgroundColorLightMode
        clientLogosColorDarkMode
        clientLogosColorLightMode
        fontColorDarkMode
        fontColorLightMode      
      }
      theme {
        switchToDarkMode
      }
      career(id: $id) {
        copyright
        darkTheme
        webpages {
            about_us {
              language
            }
            contact {
              language
            }
            case_study {
              slug
              language
            }
            blogs_overview {
              language
            }
            cases_overview {
              language
            }
            careers_overview {
              language
            }	
            services_overview {
              language
            }
            blog {
              language
              slug
            }
            career {
              language
              slug
            }
            homepage {
              slug
              language
            }
            service_design {
              slug
              language
            }
            service_technical {
              slug
              language
            }
            service_casey_new {
              slug
              language
            }
            service_casey {
              slug
              language
            }
            plainpage {
              language
              slug
            }
          }
        seo {
          metaData {
            metaTitle
            metaDescription
            keywords
          }
        }
        language
        slug
        intro {
          header {
            supheading
            heading
          }
          body
          background {
            url
          }
          backgroundColor
        }
        pointslist {
          heading
          item {
            richText
          }
        }
        cta1 {
          text {
            heading
            description
          }
          link {
            label
            path
          }
        }
        logos {
          header {
            supheading
            heading
          }
          logos {
            url
            alternativeText
            mime
            ext
          }
          affix
        }
        pointstabs {
          header {
            supheading
            heading
          }
          services {
            heading
            media {
              media {
                url 
              }
            }
            body {
              richText
            }
            link {
              label
              path
            }
          }
        }
        cta2 {
          text {
            heading
            description
          }
          link {
            label
            path
          }
        }
        
        faqs {
          header {
            heading
            supheading
          }
          faqs {
            heading
            richText
          }
        }
        collage {
          url
          alternativeText
          mime
          ext
        }
      }
      headers(where: {language: $language}) {
        ...SiteHeader
      }
      footers(where: { language: $language }) {
        ...SiteFooter
      }
    }
    logo: file(relativePath: {eq: "placeholder.jpg"}) {
      childImageSharp {
        fixed(width: 155, height: 105, cropFocus: CENTER) {
          srcSetWebp
          src
        }
      }
    }
  }
`

export default CareerDetail
