import React from "react"
import { domain, stripString } from "../utils";
import Media from "./media";
import Teaser from "./teaser";
import Markdown from 'markdown-to-jsx'; 
import Link from "./link";
import { CustomHr } from "./CustomHr";

const Cta = ({ component, className, showNumber, showTeaser, author, showCtaImageOnly, openingHour, anchorId, shouldNotLazyload, greySectionBgColor, websiteFontColor}) => {
    if (!className) {className = ''}
  return (
    <section 
      className={'section section--grey cta '+className} 
      id={anchorId ? stripString(anchorId) : ''} 
      style={{backgroundColor: greySectionBgColor}}
    >
        {showTeaser && (<Teaser author={author} shouldNotLazyload={shouldNotLazyload} fontColorLightMode={websiteFontColor} />)}
        <div className="container">
            <div className="row">
  
                <div className="col-md-6 offset-md-3 text-center">
                  {showCtaImageOnly ? (
                    <div> <Media media={component.media} width={'semiMedium' ||component.width} withCaption={false} shouldNotLazyload={shouldNotLazyload} /></div>
                  ) :  (
                    <>
                  
                    <header className="cta__header header">
                        <h2 className="header__heading cta__heading">
                            {component.text.heading}
                        </h2>
                    </header>
                    <div className="cta__content vw-50">
                      <p>
                        <Markdown options={{
                          overrides: {a: {
                            component: Link
                          },                           
                          hr: { 
                              component: CustomHr,
                              props: {
                                borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                              }
                            }  
                          }
                        }}>
                          {component.text.description || ''}
                        </Markdown> 
                      </p>
                    </div>
                    {showNumber && (
                    <div className="cta__number">
                      <h3>+49 (30) 505 671 67</h3>
                      <p>{openingHour ? openingHour : "Montag - Freitag 9:00 - 18:00 Uhr"} </p>
                    </div>
                    )}
                    <div className="cta__links" style={{justifyContent: component.link.length === 1 ? 'center' : 'space-around'}}>
                        {component.link.map((link, index) => {
                            return (
                                <div className="cta__link">
                                    <a href={link.path} className="button">
                                        <span className="button__text">
                                            {link.label}
                                        </span>
                                    </a>
                                </div>
                            )
                        })}
                    </div>
                 </>
                )}
                </div>

            </div>
        </div>
    </section>   
  );
}

export default Cta


